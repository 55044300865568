// App.js

import React, { useState } from 'react';
import ShopifyLogin from './ShopifyLogin'; // Correcting the import path

function App() {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="App">
      <button onClick={openPopup}>Open Login Popup</button>
      {showPopup && <ShopifyLogin onClose={closePopup} />}
    </div>
  );
}

export default App;
