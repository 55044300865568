// ShopifyLogin.js

import React, { useState } from 'react';
import './ShopifyLogin.css';

const ShopifyLogin = ({ onClose }) => { // Correcting the component name and props
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');



  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    // Handle login logic here, for simplicity, just log the credentials

    // console.log(email, password);
    const response = await fetch(
      "https://gzd1kcywwk.execute-api.us-east-1.amazonaws.com/api/partner/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      }
    );
    const partnerObject =await response.json()


    // Send token to parent window
    window.opener.postMessage(partnerObject, '*');

    // Close the pop-up window
    window.close();
  };



  return (
    <div className="popup">
      <div className="popup-content">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div>
            <label htmlFor="email">Email:</label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

export default ShopifyLogin;
